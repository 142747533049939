import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import store from "../store";
Vue.use(VueAxios, axios);

Vue.axios.interceptors.request.use(function (config) {
  var timestamp = Vue.$cookies.get("timestamp");
  if (timestamp && store.getters.getTimestamp()) {
    if (timestamp > store.getters.getTimestamp()) {
      window.location.href = "/";
    }
  }
  if (Vue.$cookies.isKey("token"))
    config.headers.common["Authorization"] = `Bearer ${Vue.$cookies.get(
      "token"
    )}`;

  config.headers.common["API-KEY"] = process.env.VUE_APP_API_KEY;
  config.headers.common["BrandId"] =
    store.getters.getCurrentBrandID() == null
      ? 0
      : store.getters.getCurrentBrandID();
  return config;
});
// Add API response
Vue.axios.interceptors.response.use(
  function (response) {
    return Promise.resolve(response.data);
  },
  function (error) {
    console.log(error);
    if (error.response.status == 401) {
      Vue.$cookies.remove("token");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

let baseURL = "";
let host = window.location.hostname;
let brand = host.split(".")[0].replace("booth", "");
var subdomain = host.split(".");
subdomain.shift();
subdomain = subdomain.join(".");

var mode = "prod";
// if (brand == "hourglass") subdomain = "dosetech.co";
if (
  brand == "dev-crm-pos" ||
  brand == "d" ||
  process.env.NODE_ENV == "development"
) {
  baseURL = process.env.VUE_APP_BASE_URL;
  mode = "dev";
} else if (brand == "hourglass-pos")
  baseURL = "https://api-hourglass-booth-prod.dosetech.co/api";
else if (brand == "clarins") {
  baseURL = "https://clubbeaute-mobile-api.dosetech.co/api";
} else if (brand == "dcrm-demo") {
  baseURL = "https://api-dcrm-demo-booth.dosetech.co/api";
} else {
  baseURL = `${window.location.protocol}//api-${brand}-booth-${mode}.${subdomain}/api`;
}

document.title =
  brand.charAt(0).toUpperCase() + brand.replace("-pos", "").slice(1);
if (mode == "dev") {
  document.title = "D-CRM";
}
var link = document.querySelector("link[rel~='icon']");
if (!link) {
  link = document.createElement("link");
  link.rel = "icon";
  document.head.appendChild(link);
}
link.href = `/images/${brand}.ico`;

Vue.prototype.$baseUrl = baseURL;
Vue.prototype.$baseTheme =
  brand == "dev-crm-pos"
    ? process.env.VUE_APP_BASE_THEME
    : brand == "hourglass-pos"
    ? "https://api-hourglass-booth-prod.dosetech.co/"
    : brand == "dcrm-demo"
    ? "https://api-dcrm-demo-booth.dosetech.co/"
    : `${window.location.protocol}//api-${brand}-booth-${mode}.${subdomain}/`;
// Vue.use(VueAxios, axios);
Vue.prototype.$axios = axios;
export { axios };
